import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_IMAGE, ServerUrl,  Token,  config, FormatNumber, API_URL} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getAge, getResultHead, getPosition, longDate, getRank, sort, getCAPoint, getBritishDate, getNumWorkDays} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'


const Transcript =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [remark, setRemark]=useState([])
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo[0]);


let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [assesmentGrade, setAssesmentGrade]=useState([])
const staff  = useSelector(state => state.rootReducer.staff);
 const [studentList, setStudentList] = useState([]);

const [student, setStudent]=useState([])
const [disabledResult, setDisabledResult]=useState([])
const [assessment, setAssessment]=useState([])
const [house, setHouse]=useState([])
const [grade, setGrade] = useState([])
const [grademark, setGrademark]=useState([])
    const [scores, setScores]=useState([])
    const [onlyCategory, setOnlyCategory]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [remarkResults, setRemarkResults] = useState([])


    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        resultHead:'Secondary',
        orderBy:'',
        session:[]
    })

    const [resultSession, setResultSession]=useState([])
    const [category, setCategory]=useState([])
    const [attendance, setAttendance]=useState([])
    const [atResult, setAtResult]=useState({
        attendanceStart:'',
        attendanceEnd:'',
        nextTermBegin:'',
        nextTermBeginDay:'',
        totalOpen:0
    })




    const [firstTerm, setFirstTerm]=useState([])
    const [secondTerm, setSecondTerm]=useState([])
    const [thirdTerm, setThirdTerm]=useState([])






    const getScores =(code)=>{
        let scr =  [...scores];
        const rs = scr.filter(item=>item.studentCode===code)

//filter subject here
const newSubject=	rs.map(e=>e['subjectCode'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>rs[e])
                        .map(e=>rs[e])

 return newSubject.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1);
       
    
    }



    
const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)


const getGrade =(score, field)=>{
   
    const ans = grademark.filter(itm=>itm.classtype===result.studentClass.classtype&& parseFloat(score) >= parseFloat(itm.minimum) && parseFloat(score) <= parseFloat(itm.maximum));
        const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
        const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
       
    
    return field ==='grade'?String(grade):String(remark)
    }
    




   const getResultScore =(studentCode, examCode,  subjectCode, field, dataList)=>{
    const ans = dataList.filter(item=>item.studentCode===studentCode && item.examCode===examCode && item.subjectCode===subjectCode)
return ans.length!==0?ans[0][field]:'0'
    }


const mergeAllResult =()=>{
    const answer = [...firstTerm, ...secondTerm, ...thirdTerm]
    //const answer = allRes.filter(item=>item.studentCode==='620610393c6db');

    var list = [];
    for (var i = 0; i< answer.length;  i++) {


        var average = ((parseFloat(getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'totalAverage', answer))+parseFloat(getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'totalAverage', answer))+parseFloat(getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'totalAverage', answer)))/3).toFixed(2)
        
        var overallTotal = parseFloat(getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'total', answer))+parseFloat(getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'total', answer))+parseFloat(getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'total', answer))


        var grade =getGrade((overallTotal/3), 'grade')
        var remark = getGrade((overallTotal/3), 'remark')

       



        list.push({
            subjectName:answer[i].subjectName,
            subjectCode:answer[i].subjectCode,
            studentCode:answer[i].studentCode,
            studentName:answer[i].studentName,

            firstTermExamCode:result.session.firstTerm,
            firstTerm1stCA:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'firstCA', answer),
            firstTerm2ndCA:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'secondCA', answer),
            firstTermExam:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'exam', answer),
            firstTermTotal:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'total', answer),
            firstTermComment:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'comment', answer),
            firstTermGrade:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'grade', answer),
            firstTermAbsent:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'absent', answer),
            firstTermRemark:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'remark', answer),
            firstTermPosition:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'position', answer),
            firstTermAverage:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'totalAverage', answer),


            secondTermExamCode:result.session.secondTerm,
            secondTerm1stCA:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'firstCA', answer),
            secondTerm2ndCA:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'secondCA', answer),
            secondTermExam:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'exam', answer),
            secondTermTotal:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'total', answer),
            secondTermComment:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'comment', answer),
            secondTermGrade:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'grade', answer),
            secondTermAbsent:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'absent', answer),
            secondTermRemark:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'remark', answer),
            secondTermPosition:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'position', answer),
            secondTermAverage:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'totalAverage', answer),



           thirdTermExamCode:result.session.thirdTerm,

           thirdTerm1stCA:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'firstCA', answer),

           thirdTerm2ndCA:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'secondCA', answer),

           thirdTermExam:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'exam', answer),
           thirdTermTotal:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'total', answer),
           thirdTermComment:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'comment', answer),
           thirdTermGrade:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'grade', answer),
           thirdTermAbsent:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'absent', answer),
           thirdTermRemark:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'remark', answer),
           thirdTermPosition:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'position', answer),

           thirdTermAverage:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'totalAverage', answer),
           average:(overallTotal/3).toFixed(2),
           grade:grade,
           remark:remark,
           overallTotal:overallTotal 


        })

    }

    
   setScores(list)

}

    const fetchAnalysisA =(section)=>{
        fetchAnalysisB(section)
        setNotice({...notice, isLoading: true}) 
        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+result.session.firstTerm+'" and e.sectionID ="'+section+'" '
        
        const fd = new FormData();
        fd.append("sql", final);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
            
             if(response.data.length!==0){
        setFirstTerm(response.data)
            }else{
                setFirstTerm([]) 
            }  
        })
        }

    const fetchAnalysisB =(section)=>{
       
      fetchAnalysisC(section)
        
       setNotice({...notice, isLoading: true}) 
        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+result.session.secondTerm+'" and e.sectionID ="'+section+'" '
       
       const fd = new FormData();
       fd.append("sql", final);
       fd.append('jwt', Token)
       let url = ServerUrl+'/fetchBySql_controller';
      axios.post(url, fd, config).then(response=>{
            if(response.data.length!==0){
                setSecondTerm(response.data)
           }else{
               setSecondTerm([]) 
           }  
       })

    }



    const fetchAnalysisC =(section)=>{

        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+result.session.thirdTerm+'" and e.sectionID ="'+section+'" '

        
        const fd = new FormData();
        fd.append("sql", final);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
       axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){
                setThirdTerm(response.data)
            }else{
                setThirdTerm([]) 
            }  
        }).finally(()=>{
            setNotice({...notice, isLoading: false}) 
        })
    }





    const fetchAllResultSession =()=>{

        var sql ="SELECT r.code, r.firstTerm, r.secondTerm, r.thirdTerm, r.sessionCode, s.startYear from tbl_result_session r, tbl_school_session s where r.sessionCode =s.ID " ;

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            setResultSession(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }


    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   



        if(values.session.length===0){
            errors.session ='Please select session';
        }   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        } 

        if(values.orderBy.length===0){
            errors.orderBy ='Please select order ';
        }

        return errors;
        }
        
        const fetchSettings =()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
            axios.post(url, fd, config).then(result=>{
                setSettings(result.data[0])
              
            })
        }



   
    
    const fetchRemarks =()=>{
        var sql ="SELECT code, teacherRemark, headofSchool, teacherName, headRemark, teacherName, headName, resultHead, studentCode from tbl_result_remarks where  examType = '"+result.session.thirdTerm+"' and sectionID = '"+result.studentClass.value+"'" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0){
        setRemarkResults(response.data)
    }else{
        setRemarkResults([])
    }
    
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}



const handleSelectSession =(option, action)=>{     
    setResult({...result, [action.name]:option, studentClass:[], orderBy:[]});
    fetchExamAttendance(option.thirdTerm)
    setStudentList([])
    setScores([])
    setStudent([])
    setFirstTerm([])
    setSecondTerm([])
    setThirdTerm([])
    setErrors({...errors, [action.name]:''})

}

const handleSelectSorting =(option, action)=>{     
    setResult({...result, [action.name]:option});
    
    setErrors({...errors, [action.name]:''})
    
    mergeAllResult()
    getAttendance()
    fetchRemarks()
}



    const handleSelect =(option, action)=>{     

        
        setResult({...result, [action.name]:option, orderBy:[]});
        
        fetchStudentList(result.session.thirdTerm, option.value)
        fetchAnalysisA(option.value)
        fetchClassTeaher(option.classID, option.value)
            fetchGradeMark(option.classtype)
            setStudent([])
        setErrors({...errors, [action.name]:''})
            
    }


    const fetchStatistics =()=>{
        setNotice({...notice, isLoading: true}) 
       

        let final =   'SELECT   rank() over (order by  CAST(sum(e.total) AS decimal(10,2)) desc) position, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, e.subjectCode, e.classID, e.sectionID, e.maxMark, e.absent from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+result.session.thirdTerm+'" and e.sectionID ="'+result.studentClass.value+'" group by e.studentCode '


     const fd = new FormData();
    fd.append("sql", final);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
         if(result.data.length!==0){
            
    setStudent(result.data)
        }else{
            setStudent([]) 
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }

    

    
    
    
    
    const fetchStudentList =async(exam, section)=>{
        setNotice({...notice, isLoading: true}) 
    let url = API_URL+'/student/disabled/'+section+'/'+exam;
            await   axios.get(url,  config).then(result=>{

        if(result.data.data.length!==0){

            setStudentList(result.data.data)

            let studentList = result.data.studentList
            let std = studentList.length!==0?studentList[0].studentList:[]
            const options = std.length!==0? JSON.parse(std):[]

            if(options.length!==0){
                const rs = options.map((item)=>item.studentCode)
                setDisabledResult(rs)
            }else{
                
        setDisabledResult([])
            }

            }else{
                setStudentList([]) 
            } 
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
       
    })
    }

      
    

        
    
        const fetchAssessment =()=>{
       
            let url = API_URL+'/exam/result/session/all';
            axios.get(url,  config).then(result=>{
    
                if(result.data.length!==0){
    
                    try{
    
                    
                    let settings = result.data.assessment;
                    let standard = JSON.parse(settings[0].assessment)
                    let cat = JSON.parse(settings[0].category)
                    let config = JSON.parse(result.data.settings[0].settings)
                    setCategory(cat.sort(sort))
                    setAssessment(standard.sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))
                    //setSettings(config)
    
                    var assesmentGrad = settings[0].assesmentGrade
    
                   // setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))
                    
                    
    
                }catch(e){
                        
                }
                }
                
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }

      
        


        const fetchGradeMark =(classType)=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_exam_settings/gradeMark/ID/1';
            axios.post(url, fd, config)
            .then(result =>{

                if(result.data.length!==0){
                    var grade = result.data.filter(item=>item.classtype===classType)
                    setGrademark(grade.sort((a, b) =>(Number(b.maximum) > Number(a.maximum)) ? 1 : -1))
                }else{
                    setGrademark([])
                }
            })             
        }


        const fetchReMark =()=>{
            var sql ="SELECT grade, gradeRemark, gradeMark from tbl_exam_settings where ID="+1;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
            
var grad = result.data[0].grade
        var gradeRemar = result.data[0].gradeRemark
        setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
        setGrade(JSON.parse(grad).sort(sort))
        }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        }


        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
        



            const fetchExamAttendance =(value)=>{
                let url = API_URL+'/exam/attendance/show/'+value
                axios.get(url, config).then(response=>{
                    
                    if(Array.isArray(response.data.data)&& response.data.data.length!==0){
                        setAtResult({...atResult, attendanceStart:response.data.data[0].startDate, 
                            attendanceEnd:response.data.data[0].endDate,
                        nextTermBegin:response.data.data[0].nextTermBegin,
                        nextTermBeginDay: response.data.data[0].nextTermBeginDay })
                       
                    }else{
                        setAtResult({...atResult, attendanceStart:'', attendanceEnd:'', nextTermBegin:'',  nextTermBeginDay:''  })
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }



               
        const fetchAllHolidays =(holiday)=>{
            var total = parseFloat(getNumWorkDays(atResult.attendanceStart, atResult.attendanceEnd));
            if(Array.isArray(holiday)&& holiday.length!==0){
                setAtResult({...atResult, totalOpen:(total - Number(holiday[0].total))*2 })
                }else{

                    setAtResult({...atResult, totalOpen:total*2 })
                }
        }

        
        const getAttendance =()=>{
            let url = API_URL+'/student/attendance/statistics/'+atResult.attendanceStart+'/'+atResult.attendanceEnd
            axios.get(url, config).then(response=>{
                if(Array.isArray(response.data.data)&& response.data.data.length!==0){
                    fetchAllHolidays(response.data.holiday)
                    setAttendance(response.data.data)
                }else{
                    setAttendance([])
                } 
            })
            .catch((error)=>{
            //Alerts('Error!', 'danger', error.message)
        })
    }



        const fetchHouse =()=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl + '/fetchField/tbl_student_master/house/ID/1';
            axios.post(url, fd, config)
            .then(result =>setHouse(result.data))
        }

        const fetchAssementGrade =()=>{
            var sql ="SELECT category, assessment, assesmentGrade from tbl_standards_setting where ID="+1;
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
        var assesmentGrad = result.data[0].assesmentGrade
        setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))
    
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }




    const getRemark =(code)=>{
        const ans = remark&&remark.filter(item=>item.code===code)
        return ans[0].remarkName
        }


            const fetchClassTeaher =(classID, sectionID)=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }


        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
               
        fetchStatistics()
            }
            },[errors])



     const handleStaffName=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
        } );
    }else{
        return ''
    } 
    }

  


       const getStaffField = (code, field) =>{   
    
        const res = staff&&staff.filter(list=>list.code===code);
        const answer = res.map((c)=>c[field] ); 
        return  String(answer);
       }


    



const getStudent =(code)=>{
    let scr =  [...student];
    const rs = scr.filter(item=>item.code===code)
    return rs[0];

}


const getAt =(code)=>{
    let scr =  [...attendance];
   const rs = scr.filter(item=>item.code===code)

   if(rs.length!==0){
       return rs[0]
   }else{
    return {A:0, P:0}
   }
   

}

const getData =(code)=>{
     let scr =  [...remarkResults];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs[0];

} 


useEffect(()=>{   
    fetchHouse()
    fetchSettings()
    fetchReMark()
    fetchAssessment()
    fetchAssementGrade()
    fetchAllResultSession() 
},[]);



        return (
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Transcript">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Class Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">
       
        <section className="col-md-4">
                <div className="form-group">
         <label> Session<span style={{color:'red'}}>*</span></label>
         <Select  options={resultSession&&resultSession.map((list, id)=>{
             return {key:id, value:list.code, label:list.startYear, firstTerm:list.firstTerm, secondTerm:list.secondTerm, thirdTerm:list.thirdTerm}
         })}    

 onChange={handleSelectSession} className={errors.session ? 'form-control form-control-danger' : ''} name="session" value={result.session} id='session'  />

 <span style={{color:'red'}}>{errors.session && errors.session}</span>
            </div>
            </section>


                    <section className="col-md-4">
                    <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                         schoolClass&&schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>   
     <section className="col-md-4">
           <div className="form-group">
         <label> Order By</label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelectSorting} className={errors.orderBy ? 'form-control form-control-danger' : ''} name="orderBy" value={result.orderBy}  /> 
<span style={{color:'red'}}>{errors.orderBy && errors.orderBy}</span>

            </div>
     
     </section>


				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

{student.length!==0?studentList.map((std, index)=>

getResultHead(result.studentClass.classtype)!=='Pre-Nursery'?<div key={index}>
<div className="card z-depth-0">
		
        
    <div>    <div className="card-block">
	
    <div className="row">

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+'/logo.png'}} src={`${API_IMAGE}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+'/logo.png'}} src={`${API_IMAGE}/about/headerlogo.png`}   alt={schoolInfo.schoolName}  />
     </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+'/'+std.gender+'.png'}} src={`${API_IMAGE}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div>
                

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> STUDENT ACADEMIC TRANSCRIPT</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>


       <tr>
                <td colSpan={2}>NAME OF STUDENT: <b>{std.studentName.toUpperCase()}</b></td>
                 <td >ADMISSION NO: <b> {std.admissionNumber} </b></td>
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                   
                </tr>
               
                <tr>
                    
                    <td colSpan={2} >CLASS: <b> {result.studentClass.label} </b></td>
                    <td >GENDER: <b>{std.gender}</b></td> 
                <td >NUMBER IN CLASS: <b>{studentList.length}</b></td>
                   </tr>
               
                

                <tr>
                    
                    <td>TIME SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
                    <td>TIMES PRESENT: <b>{(Number(getAt(std.code).P)+Number(getAt(std.code).H))} </b></td>
                    <td colSpan={2}>NEXT TERM BEGIN: <b>{longDate(atResult.nextTermBegin)} </b></td>
                </tr>
                
                   
                <tr>
                    <td colSpan={2}>TOTAL MARK OBTAINABLE: <b>{getStudent(std.code).obtainable}</b></td>
                    <td >TOTAL MARK OBTAINED: <b>{getStudent(std.code).obtained} </b></td>
                    <td >AVERAGE: <b> {getStudent(std.code).average}  </b></td>
                </tr>


            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th >SUBJECTS</th>
                   
                
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }} >THIRD TERM CAT ({getCAPoint(result.studentClass.classtype).ca})</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>THIRD TERM EXAM ({getCAPoint(result.studentClass.classtype).exam})</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>THIRD TERM TOTAL (100%)</th>
                    
                    {getPosition(result.studentClass.classtype)==='Position'? <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SUBJ POSITION</th>:[]}
                    
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SECOND TERM (100)</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>FIRST TERM (100)</th>
                    
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>CUMM. AVG</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>GRADE</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>REMARK</th>
                </tr>
            </thead>
            <tbody>
                
            {getScores(std.code).map((st, idx)=>st.overallTotal>0?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                   
                    
                    <td>{Number(st.thirdTerm1stCA) + Number(st.thirdTerm2ndCA)}</td>
                    <td>{st.thirdTermExam}</td>
                    <td>{st.thirdTermTotal}</td>
                    
                    {getPosition(result.studentClass.classtype)==='Position'?
                    <td>{st.thirdTermPosition}<sup>{getRank(st.thirdTermPosition)}</sup></td>:[]}


                    <td>{st.secondTermTotal}</td>
                    <td>{st.firstTermTotal}</td>
                    
                    <td>{st.average}</td>
                     <td>{st.grade}</td>
                     <td>{st.remark}</td>
 
                
            </tr>:[])} 

            </tbody> 
        </table>
 </div>	 
  
 <div className="col-md-12">
     <h6>*CAT Continuous Assessment Test</h6>
     <table className='table table-bordered table-responsive'>
         <tbody>
             <tr>
             <td  style={{ align:'center', valign:'center' }}  colSpan={2} rowSpan={2}><b >KEY TO GRADES </b></td>
                 <td><b>Score Range </b></td>
                 {grademark.map((data, index)=><td key={index}>{data.minimum + ' - ' + data.maximum}</td>)}

             </tr>
             <tr>
                 <td><b>Grade </b></td>
                 {grademark.map((data, index)=><td key={index}>{getGradeScore(data.gradeCode)}</td>)}
                 
             </tr>
         </tbody>
     </table>

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>

     {getResultHead(result.studentClass.classtype)==='Nursery' ||getResultHead(result.studentClass.classtype)==='Pre-Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                    
                {getData(std.code).headRemark}

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${API_IMAGE}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: getResultHead(result.studentClass.classtype)==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${API_IMAGE}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${API_IMAGE}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headofSchool, 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${API_IMAGE}/staff/signature/${getStaffField(getData(std.code).headofSchool, 'signature')}`}   alt={getStaffField(getData(std.code).headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>
 </div>



 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/view_transcript.php?token='+userToken} target='_blank'>
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
                <input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />

                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />

                <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />

                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div></div>
 

 
 {disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'black',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'white',  }}> Result not available at the momemt, please contact the Account's Department</h3>

</div>
:''} 

        </div>

        </div>:
 <div className="card z-depth-0" key={index}>
 <div className="card-block">
	
 <div className="row">

<table className="table table-responsive table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+'/logo.png'}} src={`${API_IMAGE}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+'/logo.png'}} src={`${API_IMAGE}/about/headerlogo.png`}   alt={schoolInfo.schoolName}  />
     </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+'/'+std.gender+'.png'}} src={`${API_IMAGE}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div>



<div className="row">
 <div className="col-md-12">
 <h5 style={{textAlign:'center'}}><u><b>  STUDENT ACADEMIC TRANSCRIPT</b></u></h5>
 </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
<tbody>
         <tr>
             <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
          <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
          <td >CLASS: <b> {result.studentClass.label} </b></td>
         </tr>

         <tr>
             
         <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
         <td>TIME SCHOOL OPENED: <b> {Number(getAt(std.code).P)+Number(getAt(std.code).A)+Number(getAt(std.code).H)} </b></td>
                    <td>TIMES PRESENT: <b>{(Number(getAt(std.code).P)+Number(getAt(std.code).H))} </b></td>
          
         <td >NO. IN CLASS: <b>{studentList.length}</b></td>
        
         </tr>
         

     </tbody>
</table></div>
<div className="col-md-12 table-responsive">
 <table className="table table-striped table-bordered">
     <thead>
 <tr>
                    <th >SUBJECTS</th>
                   
                
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }} >THIRD TERM </th>
                    
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SECOND TERM </th>
                    
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>FIRST TERM</th>
                </tr>
            </thead>
 {getScores(std.code).map((st, idx)=> st.absent!=='Yes'?
          <tbody key={idx}>
         <tr >
         
             <td > <b>{st.subjectName.toUpperCase()}</b></td>
             <td >{st.summerTermComment}</td>
             <td style={{ overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.secondTermComment}</td>
             <td style={{  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.firstTermComment}</td>
         
     </tr></tbody>
         :[])} 
 </table>
</div>	 
<div className="col-md-12"> <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>

     {getResultHead(result.studentClass.classtype)==='Nursery' ||getResultHead(result.studentClass.classtype)==='Pre-Nursery'?   <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                    
                {getData(std.code).headRemark}

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${API_IMAGE}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: getResultHead(result.studentClass.classtype)==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${API_IMAGE}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${API_IMAGE}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headofSchool, 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${API_IMAGE}/staff/signature/${getStaffField(getData(std.code).headofSchool, 'signature')}`}   alt={getStaffField(getData(std.code).headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>

</div>
</div> 
<div className="row no-print">
 <div className="col-md-12 pull-right">
 <form method='POST' action={ServerUrl+'/printout/result_pre_transcript.php?token='+userToken} target='_blank'>

 <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
                <input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />
                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
 <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
 
 </form>
 </div></div>
 
 </div>
 </div>):''}


        
   


   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Transcript) 